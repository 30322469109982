.searchpage{
    display: flex;
    flex-wrap: wrap;
    padding-top: 8rem;

}

.searchpage{
    padding: 0 1rem;
    padding-top: 10rem;

}
.cross{
    font-size: 3rem;
    color: #000;
    float: right;
}
.searchpage .filters{
    flex: 1 1 18%;
    border-right: 1px solid black;

}
.searchpage .maincontent{
    flex: 1 1 82%;
}

.searchbar{
    padding-left:34rem;

}
.searchbar form .box{
    margin:.7rem 0;
    padding: 1rem 3rem;
    font-size: 1.6rem;
    color:var(--black);
    border-radius: .5rem;
    border:var(--border);
    background: #f7f7f7;
    text-transform: none;
    width: 50rem;
    margin-right:2rem;
}

#categories{
    background: #f7f7f7;
    width: 15rem;
    height: 6rem;
    padding: 1rem;
}

.pricefilter{
    padding-left: 90rem;
    font-size: 1.5rem;
    padding-bottom:1rem;
}

#price{
    background:#f7f7f7;
}

.date{
    padding-top: 5rem;
    float: left;
    position: absolute;
    border-bottom: 1px solid;

}
.college_description{
    padding-top: 10rem;
    float: left;
    text-align: start;
    position: absolute;
}


.products .box-container .box{
    flex:1 1 30rem;
    box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.1);
    border-radius: .5rem;
    border:.1rem solid rgba(0,0,0,.1);
    margin-bottom: 2rem;

    position: relative;    
    height: 450px;
}

.products .box-container .box .discount{
    position: absolute;
    top:1rem; left:1rem;
    padding:.7rem 1rem;
    font-size: 2rem;
    color:var(--pink);
    background:rgba(255, 51, 153,.05);
    z-index: 1;
    border-radius: .5rem;
    color: black;

}

.products .box-container .box .image{
    position: relative;
    text-align: center;
    padding-top: 2rem;
    overflow:hidden;
}

.products .box-container .box .image img{
    height:25rem;
}

.products .box-container .box:hover .image img{
    transform: scale(1.1);
}

.products .box-container .box .image .icons{
    position: absolute;
    bottom:-7rem; left:0; right:0;
    display: flex;
}

.products .box-container .box:hover .image .icons{
    bottom:0;
}

.products .box-container .box .image .icons a{
    height: 5rem;
    line-height: 5rem;
    font-size: 2rem;
    width:50%;
    background:var(--pink);
    color:#fff;
}

.products .box-container .box .image .icons .cart-btn{
    border-left: .1rem solid #fff7;
    border-right: .1rem solid #fff7;
    width:100%;
}

.products .box-container .box .image .icons a:hover{
    background:#333;
}

.products .box-container .box .content{
    padding: 1rem 2rem;
    text-align: center;
}

.products .box-container .box .content h3{
    font-size: 2.5rem;
    color:#333;
    float :left ;
}
.products .box-container .box .content h3.right{
    font-size: 2.5rem;
    color:#333;
    float :right ;
    max-width:200px;
}

.products .box-container .box .content p{
    font-size: 1.5rem;
    color:#333;
    max-height: 350px;
    overflow: hidden;
    text-transform: none;
    padding-right: 2rem;
}

.products .box-container .box .content .price{
    font-size: 2.5rem;
    font-weight: bolder;
    padding-top: 1rem;
    color: black;

}

.products .box-container .box .content .price span{
    font-size: 1.5rem;
    color:#999;
    font-weight: lighter;
    text-decoration: line-through;
}


.btn-container{
    display: flex;
    justify-content: center;
}

.maincontent::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .maincontent {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 200ms;
    visibility: hidden;
    opacity: 0;
}
.overlay.light {
    background: rgba(255, 255, 255, 0.5);
}
.overlay .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
}
.overlay:target {
    visibility: visible;
    opacity: 1;
}
.popup {
    margin: 10rem auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #666;
    width: auto;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 1000;
    max-width: 100vw;
    overflow: hidden;
}
.light .popup {
    border-color: #aaa;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}
.popup h2 {
    margin-top: 0;
    color: #666;
    font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
}
.popup .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #666;
}
.popup .close:hover {
    opacity: 1;
}
.popup .content1 {
    max-height: auto;
    overflow: auto;
    padding: 2rem 5%;
}
.popup p {
    margin: 0 0 1em;
}
.popup p:last-child {
    margin: 0;
}

.popupimage{
    padding-bottom: 3rem;
}

.content1 .btn{
    margin-left: 10rem;
}
/* .content1{
    display: flex;
} */

.posteddate{
    float: right;
    font-size: 1.5rem;
    padding: 2rem;

}
.right{
    float: right;
    padding-right: 1%;
}
.popupicons{
    display: flex;

}
.popupicons > *{
    padding: 2rem;
    background-color: rgb(255, 255, 255);
    color: #333;
    width: 150px;
    height: 130px;
    margin-right: 5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 3rem;
    text-align: center;
}


.searchbar form{
    display:flex;
}
.popupimage img{
    border:1px solid;
    width: 400px;
    height: 400px;
}
.searchbar form{
    display: block;
}

@media (max-width:1253px){

   .right{
    padding-right: 0%;
    width: 520px;
   }
}



@media (max-width:1206px){

    .pricefilter{
        padding-left: 90rem;
    }

}
@media (max-width:1196px){

    .pricefilter{
        padding-left: 83rem;
    }

}
@media (max-width:1152px){

    .pricefilter{
        padding-left: 73rem;
    }
    .right{
        width: 480px;
    }
       .popupicons > *{
        padding: 1.5rem;
        height: 100px;
    margin-right: 3rem;

    }
}
@media (max-width:1083px){

    .pricefilter{
        padding-left: 1rem;
    }

    .right{
        float: left;
        padding-bottom: 3rem;
        width: 600px;
    }
    .popup .content1 {
        padding-left:20%;
    }
    .popupicons > *{
        padding: 3rem;
        height: 150px;
    margin-right: 5rem;
    width: 170px;
    font-size: 3rem;

    }
    .popupimage img{
        width: 550px;
        height: 510px;
    }
}
@media (max-width:1008px){

    .searchbar{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10rem;
    }

}

@media (max-width:900px){

    .popup .content1 {
        padding-left:15%;
    }

}


@media (max-width:682px){

    .searchbar{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5rem;

    }
    .popup .content1 {
        padding-left:10%;
    }
    .right{
        width: 500px;
        position: relative;
        margin-left: 10rem;
    }
    .popupimage img{
        width: 450px;
        height: 400px;
    }


}


@media (max-width:610px){

    .searchbar{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 4rem;

    }
    .searchbar form .box{
        width: 40rem;
    }
    .popup .content1 {
        padding-left:5%;
    }
    
}
@media (max-width:590px){

    
    .popup .content1 {
        padding-left:2%;
    }
    .popupicons > *{
        padding: 1rem;
        height: 120px;
    margin-right: 5rem;
    width: 140px;

    }
    .right{
        width: 450px;
    margin-bottom: 17px;

    }
    .popupimage img{
        width: 400px;
        height: 400px;
    }
}


@media (max-width:545px){

    .searchbar{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 4rem;

    }
    .searchbar form .box{
        width: 35rem;
    }
}

@media (max-width:530px){

    .footer{
        width: 81vh;
    }
}


@media (max-width:400px){

   .right{
    margin-top: -3rem;
   }

    
}



@media (max-width:500px){
    .searchbar form .box{
        width: 30rem;
    }
    .graph{
        width: 330px;
        height: 300px;
    }
    .popupicons > *{
        padding: 1rem;
        height: 96px;
    margin-right: 1rem;
    width: 100px;

    }
    .popup  {
        padding: 2px;
    }
}
@media (max-width:408px){

    
    .searchbar form .box{
        width: 35rem;
    }

    
}
@media (max-width:550px){

    
    .popup .content1 {
        padding-left:10%;
    }

    
}

@media (max-width:400px){

    
    .popup .content1 {
        padding-left:5%;
    }

    
}


.black{
    color: black;
    padding-right: 1rem;
}


.stars{
    font-size: 2.5rem;
    color: gold;
    padding: 2rem !important;
    border-bottom: 1px solid black;
}
.stars i{
    padding: .3rem;
}

@media (max-width:530px){
    .footer{
        width:81vh;
    }
    
}
